<script>
import GlobalVue from '@root/helper/Global.vue';

export default {
  extends: GlobalVue,
  name: "RightSideBar",
}
</script>

<template>
  <div class="right-sidebar">
    <div class="slimscrollright">
      <div class="rpanel-title"> Service Panel <span><i class="ti-close right-side-toggle"></i></span> </div>
      <div class="r-panel-body">
        <ul class="m-t-20 chatonline">
          <!-- <li><b>Chat option</b></li>
          <li><a href="javascript:void(0)"><img :src="assets('bo_images','users/varun.jpg')" alt="user-img" class="img-circle"> <span>Varun Dhavan <small class="text-success">online</small></span></a></li>
          <li><a href="javascript:void(0)"><img :src="assets('bo_images','users/genu.jpg')" alt="user-img"  class="img-circle"> <span>Genelia Deshmukh <small class="text-warning">Away</small></span></a></li>
          <li><a href="javascript:void(0)"><img :src="assets('bo_images','users/ritesh.jpg')" alt="user-img"  class="img-circle"> <span>Ritesh Deshmukh <small class="text-danger">Busy</small></span></a></li>
          <li><a href="javascript:void(0)"><img :src="assets('bo_images','users/arijit.jpg')" alt="user-img" class="img-circle"> <span>Arijit Sinh <small class="text-muted">Offline</small></span></a></li>
          <li><a href="javascript:void(0)"><img :src="assets('bo_images','users/govinda.jpg')" alt="user-img" class="img-circle"> <span>Govinda Star <small class="text-success">online</small></span></a></li>
          <li><a href="javascript:void(0)"><img :src="assets('bo_images','users/hritik.jpg')" alt="user-img" class="img-circle"> <span>John Abraham<small class="text-success">online</small></span></a></li>
          <li><a href="javascript:void(0)"><img :src="assets('bo_images','users/john.jpg')" alt="user-img" class="img-circle"> <span>Hritik Roshan<small class="text-success">online</small></span></a></li>
          <li><a href="javascript:void(0)"><img :src="assets('bo_images','users/pawandeep.jpg')" alt="user-img" class="img-circle"> <span>Pwandeep rajan <small class="text-success">online</small></span></a></li> -->
        </ul>
      </div>
    </div>
  </div>
</template>